import React from 'react';
import styled from 'styled-components';
import {
  sectionMargins,
  minBreakpointQuery,
  fluidFontSize,
  breakpointSizes,
  fontSize,
  brandColours,
} from '../styles';
import { Container } from './ui';

const StyledLocationBlocks = styled.section`
  ${sectionMargins()};
`;

const StyledOuter = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px solid ${brandColours.octonary};
  padding-bottom: 20px;

  ${minBreakpointQuery.small`
    margin-bottom: 50px;
    padding-bottom: 40px;
  `}
`;

const StyledInner = styled.div`
  ${minBreakpointQuery.small`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
  `}
`;

const StyledContent = styled.div`
  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}
`;

const StyledHeading = styled.h3`
  margin-bottom: 10px;
  ${fluidFontSize(
    '25px',
    '35px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
`;

const StyledAddress = styled.p`
  white-space: pre;
  line-height: 1.5;
  margin-bottom: 15px;
`;

const StyledPhone = styled.p`
  margin-bottom: 5px;
`;

const StyledEmail = styled.p``;

const LocationBlocks = ({ locations }) => {
  return (
    <StyledLocationBlocks>
      <Container>
        {locations.map(({ id, name, address, emailAddress, phoneNumber }) => (
          <StyledOuter key={id}>
            <StyledHeading>{name}</StyledHeading>
            <StyledInner>
              <StyledContent>
                <StyledAddress>{address}</StyledAddress>
              </StyledContent>
              <StyledContent>
                <StyledPhone>T: {phoneNumber}</StyledPhone>
                <StyledEmail>E: {emailAddress}</StyledEmail>
              </StyledContent>
            </StyledInner>
          </StyledOuter>
        ))}
      </Container>
    </StyledLocationBlocks>
  );
};

export default LocationBlocks;
