import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import LocationBlocks from '../components/LocationBlocks';
import ContactForm from '../components/ContactForm';
import { translateString } from '../utils';

const ContactPageTemplate = ({
  data: { datoCmsContact },
  pageContext: { locale },
}) => {
  const {
    seoMetaTags,
    slugLocales,
    title,
    subtitle,
    bannerOverline,
    bannerImage,
    formHeading,
    formText,
    locations,
  } = datoCmsContact;

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsContact}
      verticalText={translateString('Contact', locale)}
    >
      <main>
        <Banner
          overline={bannerOverline}
          heading={title}
          text={subtitle}
          image={bannerImage}
          locale={locale}
        />
        <LocationBlocks locations={locations} />
        <ContactForm heading={formHeading} text={formText} locale={locale} />
      </main>
    </Layout>
  );
};

export const ContactPageTemplateQuery = graphql`
  query ContactPageTemplateQuery($id: String!) {
    datoCmsContact(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      subtitle
      bannerOverline
      bannerImage {
        ...SubpageBannerImageFragment
      }
      formHeading
      formText
      locations {
        address
        name
        emailAddress
        phoneNumber
      }
      ...LinkFragment
    }
  }
`;

export default ContactPageTemplate;
