import React from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  fontSize,
  labelStyles,
  fullLabelStyles,
  inputStyles,
  textAreaStyles,
  formButtonStyles,
} from '../styles';
import { Container, Button, Heading } from './ui';
import { translateString, buildUrl, getSingleLocaleFields } from '../utils';

const StyledContactForm = styled.section`
  ${sectionMargins()};
`;

const StyledOuter = styled.div`
  padding: 50px 0;

  ${minBreakpointQuery.medium`
    padding: 60px 100px 80px;
    border: 1px solid ${brandColours.octonary};
    border-radius: 20px;
  `}

  ${minBreakpointQuery.mlarge`
    padding: 80px 170px 100px;
  `}

  ${minBreakpointQuery.large`
    padding: 100px 190px 120px;
  `}
`;

const StyledHeading = styled(Heading)`
  text-align: center;
`;

const StyledText = styled.p`
  text-align: center;
  margin-top: 10px;
  line-height: 1.5;
  ${minBreakpointQuery.mlarge`
    ${fontSize(20)};
    margin-top: 15px;
  `}
`;

const StyledInner = styled.form`
  padding-top: 40px;
  display: grid;
  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  `}
  ${minBreakpointQuery.mlarge`
    padding-top: 60px;
  `}
`;

const StyledLabel = styled.label`
  ${labelStyles()};
`;

const StyledLabelFull = styled.label`
  ${fullLabelStyles()};
`;

const StyledLabelText = styled.span``;

const StyledInput = styled.input`
  ${inputStyles()};
`;

const StyledTextArea = styled.textarea`
  ${textAreaStyles()};
`;

const StyledSubmit = styled(Button)`
  ${formButtonStyles()};
`;

const ContactForm = ({ heading, text, locale }) => {
  const { allDatoCmsFormConfiguration } = useStaticQuery(graphql`
    query ContactFormNoticeQuery {
      allDatoCmsFormConfiguration {
        nodes {
          locale
          contactFormSubmissionSuccessPage {
            ...LinkFragment
          }
        }
      }
    }
  `);

  const { contactFormSubmissionSuccessPage } = getSingleLocaleFields(
    allDatoCmsFormConfiguration,
    locale
  );

  const handleSubmit = e => {
    e.preventDefault();
    const myForm = document.getElementById('contact-form');
    const formData = new FormData(myForm);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        navigate(
          buildUrl(
            contactFormSubmissionSuccessPage.slug,
            contactFormSubmissionSuccessPage
          )
        );
      })
      .catch(error => alert(error));
  };

  return (
    <StyledContactForm>
      <Container>
        <StyledOuter>
          <>
            <StyledHeading>{heading}</StyledHeading>
            {text && <StyledText>{text}</StyledText>}
            <StyledInner
              id="contact-form"
              name="contact-form"
              method="POST"
              data-netlify="true"
              onSubmit={e => handleSubmit(e)}
            >
              <input type="hidden" name="form-name" value="contact-form" />
              <StyledLabelFull>
                <StyledLabelText>
                  {translateString('Your name', locale)}*
                </StyledLabelText>
                <StyledInput type="text" name="name" required />
              </StyledLabelFull>
              <StyledLabelFull>
                <StyledLabelText>
                  {translateString('Company name', locale)}
                </StyledLabelText>
                <StyledInput type="text" name="company-name" />
              </StyledLabelFull>
              <StyledLabel>
                <StyledLabelText>
                  {translateString('Phone number', locale)}*
                </StyledLabelText>
                <StyledInput type="tel" name="phone" required />
              </StyledLabel>
              <StyledLabel>
                <StyledLabelText>
                  {translateString('E-mail address', locale)}*
                </StyledLabelText>
                <StyledInput type="email" name="email" required />
              </StyledLabel>
              <StyledLabelFull>
                <StyledLabelText>
                  {translateString('Your message', locale)}
                </StyledLabelText>
                <StyledTextArea name="message" />
              </StyledLabelFull>
              <StyledSubmit type="submit">
                {translateString('Submit', locale)}
              </StyledSubmit>
            </StyledInner>
          </>
        </StyledOuter>
      </Container>
    </StyledContactForm>
  );
};

export default ContactForm;
